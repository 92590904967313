import { useState, useEffect } from "react"
import { useEthereum } from "../hooks/useEthereum"
import { Button } from "reactstrap"

import { formatEther, parseEther } from "@ethersproject/units"
import Info from "../assets/images/staking/info-icon.svg"

import { formatNumber } from "../helpers/string"
import { useModal } from "../hooks/useModal"
import ConnectModal from "./modal/ConnectModal"
import TransactionModal from "./modal/TransactionModal"
import ErrorModal from "./modal/ErrorModal"
import StakeModal from "./modal/StakeModal"
import ConnectWalletModal from "./modal/ConnectWalletModal"

const CustomStakingCard = ({
  baseToken,
  baseSymbolTxt,
  rewardSymbolTxt,
  rewardToken,
  stakingContract,
  baseSymbol,
  rewardSymbol,
  basePrice,
  rewardPrice,
}) => {
  const { ethereum, connected } = useEthereum()
  useEffect(() => {
    getBalances(ethereum.signer, basePrice, rewardPrice)
  }, [connected])

  const [baseBalanceRaw, setBaseBalanceRaw] = useState(null)
  const [baseBalance, setBaseBalance] = useState(0)
  const [baseAmount, setBaseAmount] = useState(0)
  const [baseStaked, setBaseStaked] = useState(0)
  const [rewardEarned, setRewardEarned] = useState(0)
  const [totalbaseStaked, setTotalbaseStaked] = useState(0)
  const [rewardRate, setRewardRate] = useState(0)

  const [apr, setApr] = useState(0)

  const [loading, setLoading] = useState(false)
  const [approved, setApproved] = useState(false)
  const { openModal, closeModal } = useModal()

  useEffect(() => {
    setApr(
      (86400 * rewardRate * 365 * 100 * rewardPrice) /
        (totalbaseStaked * basePrice)
    )
  }, [apr])

  const delay = (sec) => {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000 * sec)
    })
  }

  const getBalances = async (s, basePrice, rewardPrice) => {
    if (s == null) return
    if (basePrice === 0 || rewardPrice === 0) {
      console.log("prices", basePrice, rewardPrice)
      return
    }

    console.log("fetching info")

    try {
      const _baseBalanceRaw = await baseToken
        .connect(ethereum.signer)
        .balanceOf(ethereum.address)
      setBaseBalanceRaw(_baseBalanceRaw)
      setBaseBalance(parseFloat(formatEther(_baseBalanceRaw)))
    } catch (err) {
      setBaseBalance(0)
    }

    await delay(0.1)

    try {
      const _baseStaked = await stakingContract
        .connect(ethereum.signer)
        .balanceOf(ethereum.address)
      setBaseStaked(parseFloat(formatEther(_baseStaked)))
    } catch (err) {
      setBaseStaked(0)
    }

    await delay(0.1)

    try {
      const _rewardEarned = await stakingContract
        .connect(ethereum.signer)
        .earned(ethereum.address)
      setRewardEarned(parseFloat(formatEther(_rewardEarned)))
    } catch (err) {
      setRewardEarned(0)
    }

    await delay(0.1)

    try {
      const _approved = await baseToken
        .connect(ethereum.signer)
        .allowance(ethereum.address, stakingContract.address)
      const amountApproved = parseFloat(formatEther(_approved))
      const __approved = amountApproved >= baseBalance
      setApproved(__approved)
      if (_approved.isZero()) setApproved(false)
      console.log("Approved", _approved.toString())
    } catch (err) {
      console.error(err)
      setBaseBalance(0)
    }

    await delay(0.1)

    try {
      const _totalStaked = await stakingContract
        .connect(ethereum.signer)
        .totalSupply()
      const _rewardRate = await stakingContract
        .connect(ethereum.signer)
        .rewardRate()
      setRewardRate(parseFloat(formatEther(_rewardRate)))
      setTotalbaseStaked(parseFloat(formatEther(_totalStaked)))
    } catch (err) {
      setRewardRate(0)
      setTotalbaseStaked(0)
    }

    setBaseAmount(Math.floor(baseBalance))
  }

  const updateAmount = (e) => {
    if (e.target) {
      const _v = e.target.value
      if (_v < 0) {
        setBaseAmount(0)
        e.target.value = baseAmount
        return false
      }

      if (_v > baseBalance) {
        setBaseAmount(baseBalance)
        e.target.value = baseAmount
        return true
      }

      setBaseAmount(_v)
      return
    }
  }

  const awaitTx = async (res, msg) => {
    openModal(
      <TransactionModal
        message={msg}
        details={`TxHash: ${res.hash}`}
        hash={res.hash}
      />
    )
    await res.wait()
    closeModal()
    return
  }

  const approve = async () => {
    if (!connected) return connectFirst()
    setLoading(true)

    try {
      const _stakingContract = stakingContract
      const res = await baseToken
        .connect(ethereum.signer)
        .approve(_stakingContract.address, parseEther("1000000000000000000"))

      await awaitTx(
        res,
        "Approving $DOE for staking. Remember you must still stake after this. please wait..."
      )
      getBalances(ethereum.signer, basePrice, rewardPrice)
    } catch (err) {
      handleTxError(err)
    }

    setLoading(false)
  }

  const handleTxError = (err) => {
    try {
      closeModal()
    } catch {
      console.log("nothing to close")
    }

    let msg = err.toString()
    if (err.message && err.code)
      msg = `code: ${err.code}\nmessage: ${err.message}`

    openModal(<ErrorModal message={"Transaction Failed!"} details={msg} />)
    console.error(err)
  }

  const connectFirst = () => {
    openModal(
      <ConnectWalletModal
        message={
          "You must connect your wallet before claiming. Set you wallet's network to Ethereum."
        }
      />
    )
  }

  const stake = async () => {
    if (!connected) return connectFirst()
    setLoading(true)

    try {
      let _value = parseEther(baseAmount.toString())
      _value = _value.gt(baseBalanceRaw) ? baseBalanceRaw : _value

      const gas = await stakingContract
        .connect(ethereum.signer)
        .estimateGas.stake(_value)

      console.log(gas.toNumber())
      const gasLimit = Math.max(gas.toNumber() + 5000, 110159)

      const res = await stakingContract.connect(ethereum.signer).stake(_value, {
        gasLimit: gasLimit.toString(),
      })

      await awaitTx(res, `Staking ${baseAmount} $DOE, please wait...`)
      setTimeout(
        () => getBalances(ethereum.signer, basePrice, rewardPrice),
        1000
      )
    } catch (err) {
      handleTxError(err)
    }

    setLoading(false)
  }

  const claimAndExit = async () => {
    if (!connected) return connectFirst()
    setLoading(true)

    try {
      const gas = await stakingContract
        .connect(ethereum.signer)
        .estimateGas.exit()

      console.log(gas.toNumber())
      const gasLimit = Math.max(gas.toNumber() + 5000, 100000)

      const res = await stakingContract
        .connect(ethereum.signer)
        .exit({ gasLimit: gasLimit.toString() })

      await awaitTx(res, `Exiting $DOE staking pool, please wait...`)
      getBalances(ethereum.signer, basePrice, rewardPrice)
    } catch (err) {
      handleTxError(err)
    }

    setLoading(false)
  }

  return (
    <div className="col-md-6 col-sm-12 col-12">
      <div className="stakig_card_top">
        <img src={baseSymbol} alt="icon" />
        <img src={rewardSymbol} alt="icon" />
        <p>ETH/DOE Staking</p>
        <span id="Tooltip">
          <img src={Info} alt="info" />
        </span>
      </div>
      <div className="stakig_card_bottom">
        <div className="row">
          <div className="col-6">
            <span>APR</span>
            <p>{formatNumber(apr, 2)}%</p>
          </div>
          <div className="col-6">
            <span>Wallet Balance</span>
            <p>
              {formatNumber(baseBalance, 1)} {baseSymbolTxt}
            </p>
          </div>
          <div className="col-6">
            <span>Staked</span>
            <p>
              {formatNumber(baseStaked, 1)} {baseSymbolTxt}
            </p>
          </div>
          <div className="col-6">
            <span>Earned</span>
            <p>
              {formatNumber(rewardEarned, 4)} {rewardSymbolTxt}
            </p>
          </div>
        </div>
        <div className="name_amount_cards">
          <label>Amount to Stake (max: {baseBalance})</label>
          <div className="mb-3">
            <input type="number"
          value={baseAmount}
          max={`${baseBalance}`}
          onChange={updateAmount}
          placeholder="Amount" />
            <span>DOEETH LP</span>
          </div>
          {connected ? (
            <>
              <Button
                onClick={() => (!approved ? approve() : stake())}
                className="blue_btn h50 w-100 btn btn-secondary"
              >
                Stake
              </Button>
              <Button onClick={claimAndExit} className="blue_btn unstake h50 w-100 btn btn-secondary">
                Claim and Unstake
              </Button>
            </>
          ) : (
            <Button onClick={connectFirst}></Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomStakingCard
