const pairSubGraph =
  "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2"
const query = `
{
    pair(id: "0xa626eb9cc7dec00703586414d0811e1ba2021443") {
      token0Price
    }
}
`

export const fetchPrice = async () => {
  const res = await fetch(pairSubGraph, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: query,
    }),
  })

  const result = await res.json()
  return parseFloat(result.data.pair.token0Price)
}

export const fetchTokenPrice = async (pair) => {
  // SHIBA-ETH 0x811beed0119b4afce20d2583eb608c6f7af1954f

  const query = `
    {
        pair(id: "${pair}") {
          token0Price
        }

        bundle(id: 1) {
          ethPrice
        }
    }
    `

  const res = await fetch(pairSubGraph, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: query,
    }),
  })

  const result = await res.json()
  const tokenPrice = parseFloat(result.data.pair.token0Price)
  const ethPrice = parseFloat(result.data.bundle.ethPrice)

  console.log("token price:", tokenPrice, ethPrice, ethPrice / tokenPrice)
  return ethPrice / tokenPrice
}
