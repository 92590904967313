import React, { Component } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
// pages
import Header from "./components/common/header/header"
import Footer from "./components/common/footer/footer"

import Home from "./pages/home/home"
import AboutUs from "./pages/aboutus/aboutus"
import Governance from "./pages/governance/governance"
import GovernanceDetails from "./pages/governancedetails/governancedetails"
import Buy from "./pages/buy/buy"
import Staking from "./pages/staking/staking"
import NewsList from "./pages/news/newsList"
import NewsDetails3 from "./pages/news/news3Details"
import ModalWrapper from "./components/ModalWrapper"
import "./App.scss"
import "./assets/css/main.scss"

class App extends Component {
  render() {
    return (
      <div className="App">
        <ModalWrapper>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/governance" element={<Governance />} />
              <Route
                path="/governance-detail"
                element={<GovernanceDetails />}
              />
              <Route path="/buy" element={<Buy />} />
              <Route path="/staking" element={<Staking />} />
              <Route path="/news-list" element={<NewsList />} />
              <Route path="/news-detail-3" element={<NewsDetails3 />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </ModalWrapper>
        {}
      </div>
    )
  }
}

export default App
