import React, { useEffect, useState } from "react"
import { useEthereum } from "../../hooks/useEthereum"
import { useModal } from "../../hooks/useModal"
import "./ConnectWalletModal.scss"

const ConnectWalletModal = ({ message }) => {
  const [isTrust, setIsTrust] = useState(false)
  const [loading, setLoading] = useState(false)
  const { closeModal } = useModal()
  const { connectBrowserSigner, connectWalletConnect } = useEthereum()

  useEffect(() => {
    const trusted = window.ethereum ? window.ethereum.isTrust : false
    setIsTrust(trusted)
  }, [])

  const _connectMetamask = async () => {
    if (!window.ethereum)
      return alert("Metamask browser extension not installed")

    setLoading(true)
    try {
      await connectBrowserSigner()
      setLoading(false)
      closeModal()
    } catch (err) {
      setLoading(false)
      console.error("Connect error", err)
    }
  }

  const _connectWalletConnect = async () => {
    setLoading(true)
    try {
      await connectWalletConnect()
      setLoading(false)
      closeModal()
    } catch (err) {
      setLoading(false)
      console.error("Connect error", err)
    }
  }
  return (
    <div className="wallet_modal_inner"
     
    >
      <div className="modal_inner">
        <h3 className="modal_heading">Connect Wallet</h3>

        <p className="modal_p white_text">{message}</p>

        <p className="modal_p mb-4">
          We recommend the{" "}
          <a href="https://metamask.io">Metamask browser wallet</a> for chrome
          and firefox. Learn more About Ethereum Wallets here:
          <a href="https://ethereum.org/en/wallets/">ethereum.org/en/wallets</a>
          .
        </p>

        <button
          disabled={loading}
          onClick={_connectMetamask}
          className="blue_btn h50 plr-30 btn btn-secondary w-100"
          style={{ marginBottom: "10px", color: "white" }}
        >
          <span className="text">
            {isTrust
              ? "Connect TrustWallet / Metamask"
              : "Connect Via Metamask"}
          </span>
        </button>
        {!isTrust ? (
          <button
            disabled={loading}
            onClick={_connectWalletConnect}
            // className="modal_btn"
            className="blue_btn h50 plr-30 btn btn-secondary w-100"
            style={{ color: "white" }}
          >
            <span className="text"> Connect Via WalletConnect </span>
          </button>
        ) : null}
      </div>
    </div>
  )
}

export default ConnectWalletModal
