import React from "react";
import { Link } from "react-router-dom";

import './footer.scss'
const Footer = () => {
  return ( 
    <div className="footer">
      <div className="container">
        <h3 className="common-heading">Contact us</h3>
        <div className="row emails-footer">
          <div className="col-lg-4 col-md-4 col-sm-4 col-12">
            <a href={"mailto:info@kudoe.io"}>info@kudoe.io</a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-12">
            <a href={"mailto:info@kudoe.io"}>marketing@kudoe.io</a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-12">
            <a href={"mailto:info@kudoe.io"}>development@kudoe.io</a>
          </div>
        </div>
        <div className="links-footer">
          <Link to="/">CoinMarketCap</Link>
          <Link to="/">Etherscan</Link>
          <Link to="/">CoinGecko</Link>
          <Link to="/">DexTools</Link>
          <Link to="/">Privacy Policy</Link>
        </div>
        <p className="copyright">Cryptoasset Disclaimer: Trading/Minting crypto-assets carries a high level of risk, and may not be suitable for all investors. The possibility exists that you could sustain a loss of some or all of your initial investment and therefore you should not invest money that you cannot afford to lose.</p>
      </div>
    </div>
   );
}
 
export default Footer;