import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Fade } from "react-awesome-reveal";


// images
import KudoeTokenomics from '../../assets/images/home/kudoetokenomics.png' 

const TokenomicsHomeTab = () => {
  return (
    <div>
      <div className='row'>
        <div className='col-xl-6 col-lg-5 col-md-12 col-sm-12 text-md-center mb-lg-0 mb-4'>            
          <Fade triggerOnce={true} fraction={`0.6`}>
            <img src={KudoeTokenomics} alt="doe" className='img-fluid'/>
          </Fade>   
        </div>
        <div className='col-xl-6 col-lg-7 col-md-12 col-sm-12'>
          <h2 className='common-heading mb-lg-4 mb-3'>KUDOE Tokenomics</h2>
          <Tabs
            defaultActiveKey="holders"
            id="justify-tab-example"
            className="custometab"
            justify
          >
            <Tab eventKey="holders" title="Non NFT Holders">
              <div className='row align-items-center'>                
                <div className='col-12 ps-lg-5 pe-lg-5 mt-lg-4'>
                  <div className='hometabtext border-below'>
                    <div className='row'>
                      <div className='col-4'>
                      <Fade triggerOnce={true}>
                        <div className='buy'>
                          <p>Buy Tax</p>
                          <div>8<span>%</span></div>
                        </div>
                      </Fade>
                      </div>
                      <div className='col-8'>
                        <div className='listText'>
                        <Fade triggerOnce={true} cascade damping={0.2}>
                          <p><span>2%</span> Marketing</p>
                          <p><span>2%</span> Development</p>
                          <p><span>2%</span> Liquidity Wallet (Not auto liquidity)</p>
                          <p><span>2%</span> Manual Burn</p>
                        </Fade>
                        </div>                  
                      </div>
                    </div>
                  </div>
                  <div className='hometabtext'>
                    <div className='row'>
                      <div className='col-4'>
                      <Fade triggerOnce={true}>
                        <div className='buy'>
                          <p>Sell Tax</p>
                          <div>10<span>%</span></div>
                        </div>
                      </Fade>
                      </div>
                      <div className='col-8'>
                        <div className='listText'>
                        <Fade triggerOnce={true} cascade damping={0.2}>
                          <p><span>3%</span> Marketing</p>
                          <p><span>3%</span> Development</p>
                          <p><span>2%</span> Liquidity Wallet (Not auto liquidity)</p>
                          <p><span>2%</span> Manual Burn</p>
                        </Fade>
                        </div>                  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="nonholders" title="NFT Holders">
              <div className='row align-items-center'>
                <div className='col-12 ps-lg-5 pe-lg-5 mt-lg-4'>
                  <div className='hometabtext border-below'>
                    <div className='row'>
                      <div className='col-4'>
                      <Fade triggerOnce={true}>
                        <div className='buy'>
                          <p>Buy Tax</p>
                          <div>6<span>%</span></div>
                        </div>
                      </Fade>
                      </div>
                      <div className='col-8'>
                        <div className='listText'>
                        <Fade triggerOnce={true} cascade damping={0.2}>
                          <p><span>2%</span> Marketing</p>
                          <p><span>2%</span> Development</p>
                          <p><span>1%</span> Liquidity Wallet (Not auto liquidity)</p>
                          <p><span>1%</span> Manual Burn</p>
                        </Fade>
                        </div>                  
                      </div>
                    </div>
                  </div>
                  <div className='hometabtext'>
                    <div className='row'>
                      <div className='col-4'>
                      <Fade triggerOnce={true}>
                        <div className='buy'>
                          <p>Sell Tax</p>
                          <div>8<span>%</span></div>
                        </div>
                      </Fade>
                      </div>
                      <div className='col-8'>
                        <div className='listText'>
                        <Fade triggerOnce={true} cascade damping={0.2}>
                          <p><span>3%</span> Marketing</p>
                          <p><span>3%</span> Development</p>
                          <p><span>1%</span> Liquidity Wallet (Not auto liquidity)</p>
                          <p><span>1%</span> Manual Burn</p>
                        </Fade>
                        </div>                  
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </Tab>          
          </Tabs>
        </div>
      </div>
    </div>
    
  );
}

export default TokenomicsHomeTab;