import React, { useRef } from 'react'
import { Button } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import HeadingTextBtn from '../../components/common/headinTextBtn/headingTextBtn';
import TokenomicsHomeTab from './tokenomicsHomeTab';
import ExplorerCard from './explorerCard'
import NewsCard from './newsCard'
// animation 
import { Fade } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";




// images
import DownArrow from '../../assets/images/home/down.svg'
import Earn from '../../assets/images/home/earn.png'

import LooksRare from '../../assets/images/home/LooksRare.png'
// import DogCard from '../../assets/images/home/dogcard.png'
import Twitter from '../../assets/images/home/social-1.svg'
import Telegram from '../../assets/images/home/social-2.svg'
import Discord from '../../assets/images/home/social-3.svg'
import Instagram from '../../assets/images/home/social-4.svg'
import Medium from '../../assets/images/home/social-5.svg'
import Reddit from '../../assets/images/home/social-6.svg'
import PlayBtn from '../../assets/images/home/play-btn.svg'
import VideoImage from '../../assets/images/home/video.png'
import ArrowBlue from '../../assets/images/home/arrow-blue.svg'
import LogoBig from '../../assets/images/logo/logo2.svg'
import Copy from '../../assets/images/copy.svg'
import Arrow from '../../assets/images/btn-arrow-dark.svg'
import DOERangers from '../../assets/images/home/product/1.png'
import KudoeWallet from '../../assets/images/home/product/2.png'
import KudoeVerse from '../../assets/images/home/product/3.png'
import DOELeash from '../../assets/images/home/product/4.png'
import KudoeDAO from '../../assets/images/home/product/5.png'
import KudoeNFTMarketplace from '../../assets/images/home/product/6.png'
import Yahoo from '../../assets/images/yahoo.png'
import Market from '../../assets/images/market.png'
import benzinga from '../../assets/images/benzinga.png'
import Digital from '../../assets/images/digital.png'
import TechBullion from '../../assets/images/TechBullion.png'

// partner 
// import Bitmart from '../../assets/images/home/partners/Bitmart.svg'
import CMC from '../../assets/images/home/partners/CMC.svg'
import CoinGeko from '../../assets/images/home/partners/CoinGeko.svg'
import gate from '../../assets/images/home/partners/gate.svg'
import Hotbit from '../../assets/images/home/partners/Hotbit.svg'
import LBANK from '../../assets/images/home/partners/LBANK.svg'
import MEXC from '../../assets/images/home/partners/MEXC.svg'
import opensea from '../../assets/images/home/partners/opensea.svg'
import poloniex from '../../assets/images/home/partners/poloniex.svg'
import Sandbox from '../../assets/images/home/partners/Sandbox.svg'
import Uniswap from '../../assets/images/home/partners/Uniswap.svg'
// import ZT from '../../assets/images/home/partners/ZT.svg'
import UnknownPartner from '../../assets/images/home/partners/unknown.svg'

// video 
import BannerVideo from '../../assets/video/hero-desktop_HQ.mp4'
import BannerVideoMobile from '../../assets/video/hero-mobiles_HQ.mp4'

//news
import news1 from '../../assets/images/home/news/1.webp'
import news2 from '../../assets/images/home/news/2.webp'
import news3 from '../../assets/images/home/news/3.webp'
// pdf
import WP from '../../assets/docs/Kudoe_Whitepaper.pdf'

// css
import './home.scss';


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const Home = () => {
  const location = useLocation();
  React.useEffect(()=> {
    if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1))
        if (elem) {
            elem.scrollIntoView({behavior: "smooth"})
        }
    } else {
    window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
}, [location,]);
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  const videoSectionRef = useRef(null)
  const executeScroll = () => scrollToRef(videoSectionRef)
  return (
    <div>
      <div className='home-banner'>
        <div className='container position-relative'>
          <div className='banner-bg-video'>          
            <video width="100%" height="800" className='d-md-block d-none' autoPlay muted playsInline>
              <source src={BannerVideo} type="video/mp4" />
            </video>
            <video width="100%" height="300" className='d-md-none d-block' autoPlay muted playsInline>
              <source src={BannerVideoMobile} type="video/mp4" />
            </video>
          </div>
          <Slide direction={`left`} delay={1750} triggerOnce={true}>
            <div className="row">
              <div className='col-xl-5 col-lg-6 col-md-7 col-sm-12'>
                <div className='svg-banner'>
                  <img src={LogoBig} alt="logo" className='img-fluid' />
                </div>
                <HeadingTextBtn
                  subtext="A utility driven cryptocurrency and NFT project to unify all meme communities"
                  btnclass="blue_btn h70 plr-30"
                  btnlink={WP}
                  btntext="&nbsp;&nbsp;&nbsp;&nbsp;Whitepaper&nbsp;&nbsp;&nbsp;&nbsp;"
                  arrowclass="d-none"
                >
                  <Link to="/buy">
                    <Button className='blue_btn h70 plr-30 ms-2 white_btn'>Buy on Uniswap</Button>
                  </Link>
                </HeadingTextBtn>
                <div className='scroll-down'>
                  <div onClick={executeScroll}>
                    <img src={DownArrow} alt="arrow" />
                    <span>Scroll down</span>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
      {/* video section */}

      <div id="Gaming" className='videosection' ref={videoSectionRef}>
        <div className='container'>
          <div className='videosection-inner'>
            <div className='row align-items-center'>
              <div className='col-lg-7 col-md-12'>
                <div className='heading-with-btn'>
                  <h1>Putting the fun into blockchain gaming.</h1>
                  <p>Blockchain gaming should be fun and innovative, putting players first, tokenomics second. Play, Compete and immerse yourself with our innovative and community first ecosystem.</p>
                  <Button onClick={() => openInNewTab('https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-gaming')} className="blue_btn h60 arrow white_btn">Learn More<span><img src={ArrowBlue} alt="arrow"></img></span></Button>
                  <Button onClick={() => openInNewTab('https://t.me/KudoeOfficial')} className="blue_btn h60 plr-30 ms-sm-2 ms-0 mt-sm-0 mt-2 white_outline btn btn-secondary">Join our Telegram</Button>
                </div>
              </div>
              <div className='col-lg-5 col-md-12'>
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=o3c0i16q3C8"
                  width='100%'
                  className="react-player"
                  playing
                  playIcon={<img className='player-icon' src={PlayBtn} alt="icon" />}
                  light={VideoImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 2 */}
      <div className='home-section-2 mt-lg-5 mt-3'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-6 col-sm-12 text-md-left text-center mb-md-0 mb-4 order-md-2'>
              <Slide direction={`right`} delay={50} triggerOnce={true}>
                <div className='partner_section'>
                  <div>
                    <Link to="/"><img src={Uniswap} alt="partner" /></Link>
                    <Link to="/"><img src={gate} alt="partner" /></Link>
                    <Link to="/"><img src={MEXC} alt="partner" /></Link>
                    <Link to="/"><img src={poloniex} alt="partner" /></Link>
                  </div>
                  <div>
                    <Link to="/"><img src={Hotbit} alt="partner" /></Link>
                    <Link to="/"><img src={LBANK} alt="partner" /></Link>
                    <Link to="/"><img src={Sandbox} alt="partner" /></Link>
                    <Link to="/"><img src={opensea} alt="partner" /></Link>
                  </div>
                  <div>
                    <Link to="/"><img src={CMC} alt="partner" /></Link>
                    <Link to="/"><img src={CoinGeko} alt="partner" /></Link>
                    <Link to="/"><img src={UnknownPartner} alt="partner" /></Link>
                    <Link to="/"><img src={UnknownPartner} alt="partner" /></Link>
                  </div>
                </div>
              </Slide>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 ps-lg-5'>
              <Slide direction={`left`} delay={100} triggerOnce={true}>
                <HeadingTextBtn
                  heading="Our Ecosystem"
                  heading2="Partners"
                  subtext="We have partnered with leading web3 projects and companies in the blockchain industry to create a dynamic and vibrant ecosystem that is based on decentralized practicality, utility, and a strong community."
                  btnclass="blue_btn h60 arrow dark_btn"
                  btnlink="https://kudoe-tm.gitbook.io/kudoe-tm/get-started/our-partners"
                  btntext="Our Partners"
                >
                  <Link to="/buy">
                    <Button className='blue_btn h60 arrow dark_btn_outline btn btn-secondary ms-sm-2 ms-0 mt-sm-0 mt-2'>Buy<span><img src={Arrow} alt="arrow"></img></span></Button>
                  </Link>
                </HeadingTextBtn>
              </Slide>
            </div>

          </div>
        </div>
      </div>
      {/* section 3 */}
      <div className='home-section-3'>
        <div className='container'>
          <div className='row align-items-center'>

            <div className='col-lg-6 col-md-6 col-sm-12 text-md-left text-center mb-md-0 mb-4'>
              <Slide direction={`left`} delay={50} triggerOnce={true}>
                <img src={Earn} alt="earn" className='img-fluid' />
              </Slide>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 ps-lg-5'>
              <Slide direction={`right`} delay={100} triggerOnce={true}>
                <HeadingTextBtn
                  heading="Stake and Earn KDOE"
                  subtext="Earn rewards from our staking pool by staking KDOE tokens and participating in decentralized governance through Kudoe DAO."
                  btnclass="blue_btn h60 arrow dark_btn"
                  btnlink="/"
                  btntext="Coming Soon"
                />
              </Slide>
            </div>

          </div>
        </div>
      </div>
      {/* Tokenomics tabs section  */}
      <div className='nft-tab-outer'>
        <div className='nft-tab'>
          <div className='container'>
            <TokenomicsHomeTab />
          </div>
        </div>
      </div>

      {/* utilities card section  */}
      <div className='card-home-section pt-lg-5 pt-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 pt-lg-5 pb-lg-5 pb-3'>
              <h2 className='common-heading  pb-lg-5'>Our Products</h2>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-12 pe-lg-5'>
              <ExplorerCard
                cardimage={DOERangers}
                title="DOE Rangers"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-gaming/doe-rangers"
                text="The Dogs of Elon collection of 10,000 NFTs is available on the world's largest NFT Marketplace. Browse, favorite and place your bids for your favorite DOE Doge!"
              />
              <ExplorerCard
                cardimage={KudoeWallet}
                title="Kudoe Wallet"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-wallet"
                text="The Kudoe wallet, the central hub of the Kudoe ecosystem, will be a decentralized self-custody wallet hosting Kudoe's decentralized applications and utilities."
              />
              <ExplorerCard
                cardimage={KudoeVerse}
                title="KudoeVerse"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-gaming/kudoeverse"
                text="KudoeVerse is an immersive metaverse multiplayer gaming experience where players can connect with each other and engage in a variety of activities, games and events."
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-12 ps-lg-5'>
              <ExplorerCard
                cardimage={DOELeash}
                title="DOE Off the Leash"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-gaming/doe-off-the-leash"
                text="DOE off the Leash is a unique and immersive single player Sandbox RPG adventure where gamers complete a set of objectives and tasks in search of the Dogefather."
              />
              <ExplorerCard
                cardimage={KudoeDAO}
                title="Kudoe DAO"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-dao"
                text="Participate in the governance of KDOE tokens through the Kudoe DAO, which will oversee key governance decisions concerning tokenomics, grants, and rewards."
              />
              <ExplorerCard
                cardimage={KudoeNFTMarketplace}
                title="Kudoe NFT Marketplace"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/nft-marketplace"
                text="A Marketplace where you can browse and buy Dogs of Elon NFTs for a slightly lower fee."
              />
            </div>
          </div>
        </div>
      </div>
      {/* news section  */}
      <div className='news-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mt-lg-5 mt-4 mb-lg-5 mb-2'>
              <h2 className='common-heading text-center'>News</h2>
            </div>
            <div className='col-md-4 col-sm-12'>
              <NewsCard
                cardimage={news1}
                title="Kudoe™ Whitepaper Launch"
                text="Our team is thrilled to share what we have been working on for the last several months in order to make not only this launch…"
                link="https://medium.com/dogs-of-elon-community/kudoe-whitepaper-launch-c4f937a0f932"
              />
            </div>
            <div className='col-md-4 col-sm-12'>
              <NewsCard
                cardimage={news2}
                title="KDOE Token Launch"
                text="Kudoe™ is pleased to present the roadmap for the upcoming launch of the KDOE token on January 18th. This document will provide…"
                link="https://medium.com/dogs-of-elon-community/kdoe-token-launch-1fff5f300126"
              />
            </div>
            <div className='col-md-4 col-sm-12'>
              <NewsCard
                cardimage={news3}
                title="Introducing Kudoe™"
                text="Introducing Kudoe™, the exciting new brand that builds on the success of Dogs of Elon. We are proud to unveil our new branding and share…"
                link="https://medium.com/dogs-of-elon-community/introducing-kudoe-ee5db8134a91"
              />
            </div>
            {/* <div className='col-12 text-center mt-lg-5 mt-3'>
              <Link to="/news-list">
                <button className='blue_btn h50 dark_btn btn btn-secondary px-4'>Show All</button>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      {/* Join Our Community section */}
      <div id="Community" className='join-community'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-10 col-sm-12'>
              <Slide direction={`left`} triggerOnce={true}>
                <h2 className="common-heading white mb-lg-5 mb-4">Join Our Community</h2>
                <p className='w-lg-50'>Join the Kudoe community by following us on our social media accounts. Stay up to date with the latest news and updates, connect with other members of the community, and engage with us on a variety of topics related to the project.</p>
                <div className='social-links'>
                  <Fade triggerOnce={true} cascade damping={0.08}>
                    <a href="https://twitter.com/KudoeOfficial" target="_blank"><img src={Twitter} alt="icon" /></a>
                    <a href="https://t.me/KudoeOfficial" target="_blank"> <img src={Telegram} alt="icon" /></a>
                    <a href="https://discord.gg/kudoe" target="_blank"> <img src={Discord} alt="icon" /></a>
                    <a href="https://www.instagram.com/kudoe_official" target="_blank"> <img src={Instagram} alt="icon" /></a>
                    <a href="https://kudoe.medium.com/" target="_blank"> <img src={Medium} alt="icon" /></a>
                    <a href="https://www.reddit.com/r/Kudoe/" target="_blank"> <img src={Reddit} alt="icon" /></a>
                  </Fade>
                </div>
                <p className='mt-lg-5 mt-3 mb-lg-4 mb-3'><b>As Seen in the News</b></p>
                <div className='seen_news'>
                  <a href='https://finance.yahoo.com/news/dogs-elon-kudoe-top-dog-061000524.html' target="_blank"><img src={Yahoo} alt="icon" className='img-fluid' /></a>
                  <a href='https://www.marketwatch.com/press-release/from-dogs-of-elon-to-kudoe---theres-a-new-top-dog-in-town-2022-12-14?mod=search_headline' target="_blank"><img src={Market} alt="icon" className='img-fluid' /></a>
                  <a href='https://www.digitaljournal.com/pr/from-dogs-of-elon-to-kudoe-theres-a-new-top-dog-in-the-town' target="_blank"><img src={Digital} alt="icon" className='img-fluid' /></a>
                </div>
              </Slide>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
}

export default Home;