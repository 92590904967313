import create from "zustand"
import { providers } from "ethers"

const _infura = "https://mainnet.infura.io/v3/f84d990aaff7469cbba62f68a40886ec"

export const rpcURL = _infura
const arbiRPCURL =
  Math.random() < 0.9
    ? "https://arb1.arbitrum.io/rpc"
    : "https://arb-mainnet.g.alchemy.com/v2/napRaNAJHBJH1zZq1AbHrWoZMd-eDSS5"

const LOCALSTORAGEKEY = "__doe_connection"

export const useEthereum = create((set) => ({
  _wcProvider: null,
  WalletConnect: null,
  connected: false,
  ethereum: {
    rpc: new providers.JsonRpcProvider(rpcURL),
    chainId: 1,
  },
  ethPrice: "0.0",
  clearWallet: () => {
    console.log("clear wallet called!")
    try {
      useEthereum.getState().WalletConnect.disconnect()
    } catch (err) {
      console.error(err)
    }
    set((state) => ({
      ...state,
      ethereum: {
        ...state.ethereum,
        signer: undefined,
        provider: new providers.JsonRpcProvider(rpcURL),
        address: undefined,
        chainId: 1,
      },
      connected: false,
    }))
    localStorage.removeItem(LOCALSTORAGEKEY)
    localStorage.removeItem("walletconnect")
  },
  connectBrowserSigner: async () => {
    if (!window.ethereum) throw new Error("No ethereum object on window")

    // enable metamask, this can fail
    try {
      await window.ethereum.request({ method: "eth_requestAccounts" })
    } catch {
      console.log("Wallet: Browser connection failed!")
      // clear any signer data
      useEthereum.getState().clearWallet()
      return
    }
    window.ethereum.on(
      "accountsChanged",
      useEthereum.getState().connectBrowserSigner
    )
    window.ethereum.on(
      "chainChanged",
      useEthereum.getState().connectBrowserSigner
    )

    const provider = new providers.Web3Provider(window.ethereum)
    const chainId = (await provider.getNetwork()).chainId
    const signer = provider.getSigner()
    const address = await signer.getAddress()

    set((state) => ({
      ...state,
      ethereum: {
        ...state.ethereum,
        signer,
        provider,
        address,
        chainId,
      },
      connected: !!signer,
    }))

    console.log("Wallet: Browser wallet connected!")
    localStorage.setItem(LOCALSTORAGEKEY, "metamask")
  },

  connectWalletConnect: async () => {
    const { WalletConnect } = useEthereum.getState()
    const walletConnect = new WalletConnect({
      rpc: {
        1: rpcURL,
        42161: arbiRPCURL,
      },
      qrCode: true,
      pollingInterval: 30000,
    })

    const enabledWalletConnect = await walletConnect.enable()
    set({
      _wcProvider: enabledWalletConnect,
    })

    const provider = new providers.Web3Provider(walletConnect)
    const chainId = (await provider.getNetwork()).chainId
    const signer = provider.getSigner()
    const address = await signer.getAddress()

    set((state) => ({
      ...state,
      ethereum: {
        ...state.ethereum,
        signer,
        provider,
        address,
        chainId,
      },
      connected: !!signer,
    }))

    console.log("Wallet: Remote wallet connected!")
    localStorage.setItem(LOCALSTORAGEKEY, "walletconnect")
  },

  getBestProvider: (ethereum) => {
    if (ethereum.provider) return ethereum.provider
    return ethereum.rpc
  },

  fetchEthereumPrice: async () => {
    try {
      const res = await fetch(
        "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
      )
      const json = await res.json()
      set({
        ethPrice: json.USD,
      })
    } catch (err) {
      console.error("could not get price.", err)
    }
  },
  reconnect: async () => {
    const method = localStorage.getItem(LOCALSTORAGEKEY)

    if (method === "metamask")
      return useEthereum.getState().connectBrowserSigner()
    if (method === "walletconnect")
      return useEthereum.getState().connectWalletConnect()

    localStorage.removeItem(LOCALSTORAGEKEY)
  },
  initEth: () => {
    const WalletConnect = window.WalletConnect
    console.log({ WalletConnect })
    set({
      WalletConnect,
    })
    useEthereum.getState().reconnect()
  },
}))

useEthereum.getState().initEth()
