import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import { Link } from "react-router-dom"
import { Bars } from "react-loader-spinner"
import { formatEther } from "@ethersproject/units"

import Modal from "react-bootstrap/Modal"
import useEmblaCarousel from "embla-carousel-react"
import StakingPopupCard from "./stakingPopupCard"
import Data from "../../components/Data"

// images
import ETH from "../../assets/images/eth-icon.png"
import DOG from "../../assets/images/dog-icon.png"

// css
import "./staking.scss"
import { useEthereum } from "../../hooks/useEthereum"
import { formatNumbersWithCommas } from "../../helpers/string"
import {
  isArbitrum,
  lpTokenContract,
  networkName,
  shibContract,
  shibStakingContract,
  stakingContract,
  newStakingContract,
  tokenContract,
} from "../../helpers/contracts"
import { fetchPrice, fetchTokenPrice } from "../../helpers/price"
import CustomStakingCard from "../../components/CustomStakingCard"
import { useModal } from "../../hooks/useModal"

const Staking = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const { ethereum, getBestProvider } = useEthereum()
  const { openModal } = useModal()

  const [locked, setLocked] = useState(0)
  const [staked, setStaked] = useState(0)
  const [price, setPrice] = useState(0)
  const [shibPrice, setShibPrice] = useState(0)
  const [lpPrice, setLpPrice] = useState(0)
  const [unsupportedNetwork, setUnsupportedNetwork] = useState(false)

  const [emblaRef] = useEmblaCarousel()

  const getTVL = async (e) => {
    setTimeout(async () => {
      try {
        await getPrices()

        if (ethereum.chainId !== 1) return

        const _locked = await tokenContract(ethereum.chainId)
          .connect(getBestProvider(ethereum))
          .balanceOf(stakingContract(ethereum.chainId).address)
        const _staked = await stakingContract(ethereum.chainId)
          .connect(getBestProvider(ethereum))
          .totalSupply()

        const __staked = parseFloat(formatEther(_staked))
        setStaked(__staked)
        const __locked = parseFloat(formatEther(_locked))
        setLocked(__locked)
      } catch (err) {
        setShibPrice(-1)
        setPrice(-1)
        setLocked(-1)
        console.error("Catched=", err)
      }
    }, 1000)
  }

  const getPrices = async () => {
    const _price = await fetchPrice()
    setPrice(_price)
    const _shibPrice = await fetchTokenPrice(
      "0x811beed0119b4afce20d2583eb608c6f7af1954f"
    )
    setShibPrice(_shibPrice)

    console.log("Shiba Price:", _shibPrice)
    console.log("DOE Price:", _price)

    if (isArbitrum(ethereum.chainId)) await getArbiPrices()
  }

  const getArbiPrices = async () => {
    const lpContract = lpTokenContract(ethereum.chainId).connect(
      ethereum.signer
    )
    const _balanceRaw = await tokenContract(ethereum.chainId)
      .connect(ethereum.signer)
      .balanceOf(lpContract.address)

    const _totalSupplyRaw = await lpContract.totalSupply()
    const _balance = parseFloat(formatEther(_balanceRaw))
    const _totalSupply = parseFloat(formatEther(_totalSupplyRaw))

    const _lpPrice = ((_balance * 2) / _totalSupply) * price
    setLpPrice(_lpPrice)
  }

  const getTVLJsx = () => {
    if (ethereum.chainId === 1) {
      if (price > 0 && locked > 1) {
        return (
          <div>
            <h3>TVL ${formatNumbersWithCommas(locked * price)}</h3>
            <h5>
              With a total ${formatNumbersWithCommas(staked * price)} staked
              across all pools.
            </h5>
          </div>
        )
      } else if (price < 0 || locked < 0) {
        return null
      } else {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: "8rem",
            }}
          >
            <Bars
              height="80"
              width="80"
              color="#7B3FE4"
              ariaLabel="bars-loading"
              wrapperStyle={{ margin: "0 auto" }}
              wrapperClass=""
              visible={true}
            />
          </div>
        )
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getTVL(ethereum)
      const supported = ethereum.chainId !== 1 && ethereum.chainId !== 42161
      setUnsupportedNetwork(supported)
    })
  }, [ethereum])
  return (
    <div className="staking_outer">
      <div className="container">
        <h1 className="common-heading stacking-heading">Staking</h1>
        <div className="total-card">
          {/* <h3>TVL ${formatNumbersWithCommas(locked * price)}</h3>
          <h5>
            With a total ${formatNumbersWithCommas(staked * price)} staked
            across all pools.
          </h5> */}
          {getTVLJsx()}
        </div>
        <div className="reward-section">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h4 className="fw-600">Stake and Earn $KDOE</h4>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p className="mb-lg-4 mb-3">
                Earn rewards from our staking pool by staking KDOE tokens and participating in decentralized governance through Kudoe DAO.                
              </p>
              <p className="mb-lg-4 mb-3">
              Please be aware estimated APRs will likely drop over time as
                more people join the pool. First you must approve your $KDOE
                for use on the staking contract, then enter an amount and
                press stake.
              </p>             
              <a href="https://kudoe-tm.gitbook.io/kudoe-tm/kudoe-learn/how-to-stake" target="_blank">How to stake?</a>
            </div>

            <center className="tvl"></center>
            {/* <div class="network-info">
              <b>Connected Network:</b>
              {networkName(ethereum.chainId)} | <b>Connected Wallet:</b>
              <span class="tocken_number">
                {ethereum.address ? ethereum.address : "Not Connected"}
              </span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="token-pools">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-8 col-xl-10 col-lg-12 col-md-12">
              <div className="row">
                <div className="col-12 text-center">
                  <h2 className="common-heading">Token pools</h2>
                </div>
                <Data label="Token pools">
                  <div className="staking">
                    <div className="row justify-content-center">
                      {unsupportedNetwork ? (
                        <div className="error-alert">
                          <h3>
                            <i className="fas fa-exclamation-circle" />
                            Unsupported network.
                          </h3>
                          Only Ethereum and Arbitrum network is currently
                          supported.
                          <br /> Please disconnect and re-connect your wallet
                          with the appropriate network.
                        </div>
                      ) : ethereum.chainId === 1 ? (
                        <CustomStakingCard
                          baseSymbol={ETH}
                          baseSymbolTxt="ETH"
                          rewardSymbol={DOG}
                          rewardSymbolTxt="DOE"
                          rewardPrice={price}
                          basePrice={price}
                          baseToken={tokenContract(ethereum.chainId)}
                          rewardToken={tokenContract(ethereum.chainId)}
                          stakingContract={newStakingContract(ethereum.chainId)}
                        />
                      ) : null}
                    </div>
                  </div>
                </Data>

                {/* <div className="col-md-6 col-sm-12 col-12">
                  <div className="stakig_card_top">
                    <img src={DOG} alt="icon" />
                    <p>ETH/DOE Staking</p>
                    <span id="Tooltip">
                      <img src={Info} alt="info" />
                    </span>
                  </div>
                  <div className="stakig_card_bottom">
                    <div className="row">
                      <div className="col-6">
                        <span>APR</span>
                        <p>70.91%</p>
                      </div>
                      <div className="col-6">
                        <span>Wallet Balance</span>
                        <p>2,000,000 DOE</p>
                      </div>
                      <div className="col-6">
                        <span>Staked</span>
                        <p>12,000,000</p>
                      </div>
                      <div className="col-6">
                        <span>Earned</span>
                        <p>69,420 DOEDAO</p>
                      </div>
                    </div>
                    <div className="name_amount_cards">
                      <label>Staked</label>
                      <div className="mb-3">
                        <input type="text" placeholder="Amount" />
                        <span>DOEETH LP</span>
                      </div>
                      <Button
                        onClick={handleShow}
                        className="blue_btn h50 w-100"
                      >
                        Stake
                      </Button>
                      <Button
                        onClick={handleShow}
                        className="blue_btn unstake h50 w-100"
                      >
                        Claim and Unstake
                      </Button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* NFT Staking popup  */}
      <Modal
        className="stacking-popup"
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create proposal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="staking-slider">
          <div className="embla" ref={emblaRef}>
            <div className="embla__container">
              <div className="embla__slide">
                <StakingPopupCard />
              </div>
              <div className="embla__slide">
                <StakingPopupCard />
              </div>
              <div className="embla__slide">
                <StakingPopupCard />
              </div>
              <div className="embla__slide">
                <StakingPopupCard />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Staking
