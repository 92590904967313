import React, { useRef, useEffect } from "react"
import { useModal } from "../hooks/useModal"

const ModalWrapper = ({ children }) => {
  const { modalJsx, showModal, closeModal } = useModal()
  const modalAreaRef = useRef(null)
  const modalStyle = {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100vh",
    width: "100%",
    display: "flex",
    fleDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0, 0.5)",
  }

  

  function handleOutsideClick(e) {
    if (
      e.target instanceof HTMLElement &&
      !modalAreaRef.current?.children[0].contains(e.target)
    ) {
      console.log("outside click!")
      closeModal()
      document.body.style.overflow = "auto"
    }
  }

  useEffect(() => {
    const isFrag = modalJsx.type === React.Fragment
    if (!isFrag) {
      document.body.style.overflow = "hidden"
      setTimeout(() => {
        document.addEventListener("click", handleOutsideClick)
      }, 1000)
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick)
    }
  }, [modalJsx])
  return (
    <div>
      {children}
      {showModal ? (
        <div ref={modalAreaRef} style={modalStyle} className="modal_outer">
          <div className="model_white_outer">{modalJsx}</div>
        </div>
      ) : null}
    </div>
  )
}

export default ModalWrapper
