import React from "react";
import { Button } from "reactstrap";
import CoinSelectCard from "../../components/common/coinSelectCard/coinSelectCard";
import { SwapWidget } from '@uniswap/widgets';
import '@uniswap/widgets/fonts.css';
import { useEthereum } from "../../hooks/useEthereum"
// css
import './buy.scss';

const DOE_Token_List = [
  {
  "name": "Dogs of Elon",
  "address": "0xf8E9F10c22840b613cdA05A0c5Fdb59A4d6cd7eF",
  "symbol": "DOE",
  "decimals": 18,
  "chainId": 1,
  "logoURI": "https://etherscan.io/token/images/dogsofelon_32.png"
},
{
  "name": "USD Coin",
  "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  "symbol": "USDC",
  "decimals": 6,
  "chainId": 1,
  "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png"
},
]
const Buy = () => {
  const { ethereum, getBestProvider, rpcURL } = useEthereum();
  const theme = {
    primary: '#0f2052',
    secondary: '#b796f0',
    interactive: '#d1e6f1',
    container: '#e8f0fa',
    module: '#FFF',
    accent: '#7b3fe4',
    outline: '#ABD6FE',
    dialog: '#FFF',
    fontFamily: 'Montserrat',
    borderRadius: 1,
  }
  return ( 
    <div>
      <div className="buy-outer">
        <div className="container">
        <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-8 col-sm-12 col-12">
              <div className="buy-card">
              <SwapWidget
                provider={getBestProvider(ethereum)}
                JsonRpcEndpoint={rpcURL}
                tokenList={DOE_Token_List}
                hideConnectionUI={true}
                theme={theme} />
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
   );  
}
 
export default Buy;